import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import numeral from "numeral";
import * as XLSX from "xlsx";

import { saveAs } from "file-saver";

import Rest from "../../libraries/Rest";
import Alert from "../../libraries/Alert";
import * as Components from "../../components/Components";

function PayrollDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const rest = new Rest();

  /**
   * Init states
   */
  const rootPath = `/payrolls`;
  const endpoint = `/payrolls`;

  const userrootPath = `/employees`;
  const userendpoint = `/employees`;

  const [payload, setPayload] = useState();

  /**
   * State for modal items [_tmp]
   * Items always updated by employee selected
   *
   * @var array _tempItems
   */
  const [_tempItems, _setTempItems] = useState([]);
  const [showModalItem, setModalItem] = useState(false);
  const openModalItem = (type) => setModalItem(type);

  /**
   * First init
   */
  const getDataById = async () => {
    Alert.showLoading();
    const response = await rest.get(`${endpoint}/${id}`);
    Alert.close();

    // await getByEmployeeID()

    if (response?.data?.data !== undefined) {
      const data = response.data.data;
      setPayload(data);
      // console.log('hasilPayload', data.employees)
    } else {
      Alert.showMessage("Warning", "warning", "Data is not found");
      navigate(rootPath);
    }
  };

  const getByEmployeeID = async () => {
    Alert.showLoading();
    const response = await rest.get(`${userendpoint}/${id}`);
    Alert.close();

    if (response?.data?.data !== undefined) {
      const data = response.data.data;
      // console.log('hasil UserBy Id', data)
      // setPayload(data);
    } else {
      // Alert.showMessage("Warning", "warning", "Data is not found");
      // navigate(rootPath);
    }
  };

  const openDetail = (row) => {
    if (payload?.employees[row] !== undefined) {
      _setTempItems(payload.employees[row]);
      openModalItem(true);
    }
  };

  useEffect(() => {
    async function __init() {
      await getDataById();
      // await getByEmployeeID()
    }
    __init();
  }, []);

  /**
   * Action handlers
   */  
  
  const exportToBca = async () => {
    // Menampilkan loading
    Alert.showLoading();
    
    // Mendapatkan data dari API
    const response = await rest.get(`/payrolls/export_bca/${payload?.id}`);
    const Employes = await rest.get(`${endpoint}/${id}`);

    // Menutup loading
    Alert.close();
  
    if (response?.data?.data === undefined) {
      return Alert.showMessage(
        "Warning",
        "warning",
        "Data for export to BCA is not found"
      );
    }
  
    // Mendefinisikan data
    let data = response.data.data;

    let dataEmploye = Employes.data.data
    // console.log('hasil', dataEmploye)
    // const companyName = dataEmploye.companies.name || "N/A";
    const companyName = "JASA";
    console.log('hasil', companyName)

    // Mendapatkan tanggal hari ini dalam format yyyyMMdd
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0].replace(/-/g, ''); // Format: yyyyMMdd

    const monthNames = [
      "JANUARI", "FEBRUARI", "MARET", "APRIL", "MEI", "JUNI",
      "JULI", "AGUSTUS", "SEPTEMBER", "OKTOBER", "NOVEMBER", "DESEMBER"
    ];
    const monthYear = `${monthNames[today.getMonth()]} ${today.getFullYear()}`; // Format: NamaBulan YYYY
    
  
    // Mendapatkan bulan dan tahun saat ini dalam format MMYYYY
    // const monthYear = `${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getFullYear()}`; // Format: MMYYYY


    // Format data seperti yang diinginkan
    const formattedData = data.map((item, index) => {
      // Pemetaan data ke format yang diinginkan
      const mappedItem = [
        index === 0 ? "0" : "1", // Menandakan baris pertama dengan "0" dan lainnya "1"
        // Jenis Transaksi
        item.transaction_id || "",
        item.transfer_type || "",
        item.beneficiary_id || "",
        item.nip || "",
        item.receiver_name || "",
        item.amount ? item.amount.toLocaleString("id-ID") : "0",
        item.credited_acc || "",
        item.credited_acc||"",
        `${companyName} ${monthYear}`
      ];
  
      // Gabungkan elemen dengan "|" dan pastikan tidak ada elemen kosong
      return mappedItem.join("|");
    });
  
    // Menambahkan baris pertama dengan tanggal saat ini
    const headerRow = [
      `0|PY|kbbinsande|00653104||${formattedDate}||0651655055|00011|09|UPAH STAFF|PANELINDO DES 24`
    ];
  
    // Gabungkan header dan data
    const finalData = [...headerRow, ...formattedData].join("\n");
  
    // Membuat file Blob dari data
    const blob = new Blob([finalData], { type: "text/csv;charset=utf-8" });
  
    // Mengunduh file CSV
    saveAs(blob, "Template-payroll-BCA.csv");
  };
  
  
  
  // const exportToBca = async () => {
  //   /**
  //    * Get data from service
  //    */
  //   Alert.showLoading();
  //   const response = await rest.get(`/payrolls/export_bca/${payload?.id}`);
  //   Alert.close();

  //   if (response?.data?.data === undefined)
  //     return Alert.showMessage(
  //       "Warning",
  //       "warning",
  //       "Data for export to BCA is not found"
  //     );

  //   /**
  //    * Defined sheet properties
  //    */
  //   let data = response.data.data;

  //   let header = [
  //     "No",
  //     "Transaction ID",
  //     "Transfer Type",
  //     "Beneficiary ID",
  //     "Credited Acc.",
  //     "Receiver Name",
  //     "Amount",
  //     "NIK",
  //     "Remark",
  //     "Beneficiary Email",
  //     "Receiver Swift Code",
  //     "Receiver Bank Cd",
  //     "Receiver Cust. Type",
  //     "Receiver Cust. Residen",
  //   ];

  //   /**
  //    * Mapping data sesuai dengan header tabel
  //    */
  //   const formattedData = data.map((item, index) => {
  //     // Hanya gunakan properti yang sesuai dengan header
  //     const mappedItem = {
  //       No: index + 1, // Nomor otomatis
  //       "Transaction ID": item.transaction_id || "",
  //       "Transfer Type": item.transfer_type || "",
  //       "Beneficiary ID": item.beneficiary_id || "",
  //       "Credited Acc.": item.credited_account || "",
  //       "Receiver Name": item.receiver_name || "",
  //       Amount: item.amount
  //         ? `Rp ${item.amount.toLocaleString("id-ID")}`
  //         : "Rp 0",
  //       NIK: item.nik || "",
  //       Remark: item.remark || "",
  //       "Beneficiary Email": item.beneficiary_email || "",
  //       "Receiver Swift Code": item.receiver_swift_code || "",
  //       "Receiver Bank Cd": item.receiver_bank_code || "",
  //       "Receiver Cust. Type": item.receiver_customer_type || "",
  //       "Receiver Cust. Residen": item.receiver_customer_residence || "",
  //     };

  //     return header.reduce((acc, key) => {
  //       acc[key] = mappedItem[key] || "";
  //       return acc;
  //     }, {});
  //   });

  //   /**
  //    * XLSX configurations
  //    */
  //   const ws = XLSX.utils.book_new();
  //   XLSX.utils.sheet_add_aoa(ws, [header]);
  //   XLSX.utils.sheet_add_json(ws, formattedData, {
  //     origin: "A2",
  //     skipHeader: true,
  //   });
  //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  //   /**
  //    * Generate file
  //    */
  //   const excelBuffer = XLSX.write(wb, {
  //     bookType: "csv",
  //     type: "array",
  //     cellStyles: true,
  //   });
  //   const finalData = new Blob([excelBuffer], {
  //     type: "application/octet-stream",
  //   });

  //   /**
  //    * Download file automatically
  //    */
  //   saveAs(finalData, "Template-payroll-BCA.csv");
  // };
  const [isProcessing, setIsProcessing] = useState(false);

  /**
   * Action handlers
   */
  const exportToTaskReport = async () => {
    /**
     * Get data from service
     */
    Alert.showLoading();
    const response = await rest.get(`${endpoint}/${id}`);
    Alert.close();

    let data = response.data.data;

    // Header Excel
    const header = [
      "NamaPerusahaan",
      "NPWP",
      "Tahun",
      "Bulan",
      "Tgl",
      "NoBPPPh21",
      "NIK",
      "NamaWP",
      "NPWPWP",
      "AlamatWP",
      "NoRef",
      "IsByrBulanan",
      "JlhHariKerja",
      "KodeOP",
      "KodePTKP",
      "PTKP",
      "Bruto",
      "IsNonNPWP",
      "Tarif",
      "PPh",
      "DPP",
      "AkumDPP",
      "LokasiPelaporan",
      "TandaTangan",
      "Ket",
      "UserCreated",
      "DateCreated",
      "UserModified",
      "DateModified",
      "StatusPajak",
    ];

    const companyName = data.companies.name || "N/A";
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;

    function formatTanggal(tanggal) {
      const date = new Date(tanggal);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }

    // Fungsi untuk memetakan detail.remarks
    const mapRemarks = (remark) => {
      const remarkMapping = {
        "nett:TK/0": "00",
        "nett:TK/1": "01",
        "nett:k/0": "10",
        "nett:k/1": "11",
        "nett:k/2": "12",
        "nett:k/3": "13",
      };
      return remarkMapping[remark] || null; // Return null jika tidak ditemukan
    };

    // Fungsi untuk format Rupiah
    const formatRupiah = (angka) => {
      if (!angka && angka !== 0) return ""; // Jika nilai tidak ada, return string kosong
      return `${angka.toLocaleString("id-ID")}`; // Format ke Rupiah
    };

    const rows = data.employees.flatMap((employee) => {
      return employee.salary_details
        .map((detail) => {
          const kodePTKP = mapRemarks(detail.remark); // Gunakan fungsi mapRemarks
          if (!kodePTKP) return null; // Jika tidak ada mapping, return null untuk mengabaikan data

          const cleanedRemark = detail.remark.replace(/^nett:/, "");
          return {
            NamaPerusahaan: companyName,
            NPWP: employee.employees.nik,
            Tahun: year,
            Bulan: month,
            Tgl: formatTanggal(new Date().toISOString().split("T")[0]),
            NoBPPPh21: "N/A",
            NIK: employee.employees.nik,
            NamaWP: `${employee.employees.first_name} ${employee.employees.middle_name}`,
            NPWPWP: "N/A",
            AlamatWP: data.companies.address,
            NoRef: "",
            IsByrBulanan: "Yes",
            JlhHariKerja: "",
            KodeOP: "21-100-03",
            KodePTKP: kodePTKP, // Hanya data dengan mapping valid yang akan ditampilkan
            PTKP: "",
            Bruto: formatRupiah(employee.gross_salary),
            IsNonNPWP: false,
            Tarif: `${detail.tax_percent}%`,
            PPh: formatRupiah(detail.value),
            DPP: formatRupiah(employee.gross_salary),
            AkumDPP: formatRupiah(employee.gross_salary),
            LokasiPelaporan: "",
            TandaTangan: "",
            Ket: "",
            UserCreated: "",
            DateCreated: formatTanggal(new Date().toISOString()),
            UserModified: "",
            DateModified: "",
            StatusPajak: cleanedRemark,
          };
        })
        .filter(Boolean); // Hapus nilai null
    });

    const uniqueRows = rows.filter(
      (row, index, self) =>
        index ===
        self.findIndex((t) => t.NIK === row.NIK && t.KodePTKP === row.KodePTKP)
    );

    const worksheet = XLSX.utils.json_to_sheet(uniqueRows, { header });
    const range = XLSX.utils.decode_range(worksheet["!ref"]);

    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;

      worksheet[cellAddress].s = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "D9D9D9" },
        },
        font: {
          bold: true,
          color: { rgb: "000000" },
        },
        alignment: {
          horizontal: "center",
          vertical: "center",
        },
      };
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const finalData = new Blob([excelBuffer], {
      // type: "application/octet-stream",
            type: "text/csv;charset=utf-8;",
    });

    // saveAs(finalData, "Tax-report.csv");
    saveAs(finalData, "Tax-report.xlsx");
  };

  /**
   * Rendering DOM
   */
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div>
          <ol className="breadcrumb fs-sm mb-1">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={rootPath}>List payrolls</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail payroll
            </li>
          </ol>
        </div>
      </div>

      <Row className="g-3">
        <Col xl="12">
          {/* Modl detail items:per-employee */}
          <Modal
            show={showModalItem}
            onHide={() => openModalItem(false)}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Detail component items</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-condensed table-hovered">
                <thead>
                  <tr>
                    <th>Component</th>
                    <th>Type</th>
                    <th>Total</th>
                    <th>Description</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {_tempItems?.salary_details !== undefined &&
                    _tempItems.salary_details.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{v?.name}</td>
                          <td>{v?.type_text}</td>
                          <td>{numeral(v?.value).format("0,0")}</td>
                          <td>{v?.description}</td>
                          <td>{v?.remark}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => openModalItem(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Form payroll */}
          <Card className="card">
            <Card.Header className="bg-primary">
              <Card.Title
                as="label"
                className="fs-sm fw-medium mb-1 text-white"
              >
                Detail payroll
              </Card.Title>
            </Card.Header>
            <Card.Body className="bg-white">
              {payload?.status?.toUpperCase() === "S3" && (
                <div className="alert alert-danger">
                  <strong>Canceled!</strong>, this payroll already canceled by
                  admin
                </div>
              )}
              <Link to={rootPath} className="btn btn-sm btn-warning text-white">
                <i className="ri-arrow-left-line"></i> Back
              </Link>
              &nbsp;
              {payload?.status?.toUpperCase() === "S2" && (
                <button
                  type="button"
                  onClick={exportToTaskReport}
                  className="btn btn-sm btn-success text-white"
                >
                  <i className="ri-file-excel-line"></i> Download Tax Report
                </button>
              )}
              &nbsp;
              {payload?.status?.toUpperCase() === "S2" && (
                <button
                  type="button"
                  onClick={exportToBca}
                  className="btn btn-sm btn-success text-white"
                >
                  <i className="ri-file-excel-line"></i> Export to BCA Template
                </button>
              )}
              <br />
              <br />
              <Row>
                <Col sm="4">
                  <Components.Input
                    name="company_id"
                    label="Company"
                    disabled={true}
                    defaultValue={payload?.companies?.name}
                  />
                </Col>
                <Col sm="2">
                  <Components.Input
                    name="start_date"
                    label="Start Date"
                    disabled={true}
                    defaultValue={payload?.start_date}
                  />
                </Col>
                <Col sm="2">
                  <Components.Input
                    name="end_date"
                    label="End Date"
                    disabled={true}
                    defaultValue={payload?.end_date}
                  />
                </Col>
                <Col sm="2">
                  <Components.Input
                    name="status"
                    label="Status"
                    disabled={true}
                    defaultValue={payload?.status_text}
                  />
                </Col>
                <Col sm="2">
                  <Components.Input
                    name="type"
                    label="Type"
                    disabled={true}
                    defaultValue={payload?.type_text}
                  />
                </Col>
                <Col sm="12">
                  <Components.Input
                    name="remark"
                    label="Remark"
                    disabled={true}
                    defaultValue={payload?.remark}
                  />
                </Col>
              </Row>
              <hr />
              <legend>List Employees</legend>
              <table className="table table-condensed">
                <thead>
                  <tr>
                    <th>ID Employee</th>
                    <th>Employee</th>
                    <th>Gross Salary</th>
                    <th>Total Allowances</th>
                    <th>Total Deductions</th>
                    <th>Total Taxes</th>
                    <th>Nett Salary</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {payload?.employees !== undefined &&
                    payload.employees.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td>{v?.employees?.nik}</td>
                          <td>
                            {v?.employees?.first_name}{" "}
                            {v?.employees?.middle_name ?? ""}{" "}
                            {v?.employees?.last_name ?? ""}
                          </td>
                          <td>{numeral(v?.gross_salary).format("0,0")}</td>
                          <td>{numeral(v?.total_allowances).format("0,0")}</td>
                          <td>{numeral(v?.total_deductions).format("0,0")}</td>
                          <td>{numeral(v?.total_taxes).format("0,0")}</td>
                          <td>{numeral(v?.nett_salary).format("0,0")}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => openDetail(i)}
                            >
                              Detail
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PayrollDetail;
